import React from "react";
import "./inputdash.scss";
function Inputdash({
  label,
  type,
  placeholder,
  value,
  disabled,
  onchange,
  state,
  setState,
  name,
}) {
  return (
    <section className="inputdash-sec">
      <h5 className="darkcolor Carmen-semibold-font">{label}</h5>
      {type ? (
        <input
          type={type}
          placeholder={placeholder}
          className="Carmen-regular-font w-100"
          disabled={disabled}
          onChange={onchange}
          value={value}
          name={name}
          state={state}
          setState={setState}
        />
      ) : (
        <textarea
          placeholder={placeholder}
          className="Carmen-regular-font w-100 customscrollbar"
          value={value}
          onChange={onchange}
          name={name}
          disabled={disabled}
        ></textarea>
      )}
    </section>
  );
}

export default Inputdash;
