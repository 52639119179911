import React from "react";
import Button from "../button/button";
import { useNavigate } from "react-router-dom";
import "./errorPage.scss";
function ErrorPage() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="container">
        <div className="error-sec">
          <img src="/assets/images/404.svg" alt="..." className="error-img" />
          <Button
            text="Back to Home"
            className="primary-button w-100 fs-20 mx-auto"
            maxWidth="200px"
            padding="13px 26px"
            onClick={() => {
              navigate("/dashboard");
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
