import React, { useRef, useState } from "react";
import Input from "../input/input";
import Button from "../button/button";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
function Verifycode() {
  const Apiurl = process.env.REACT_APP_API_KEY;
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    otp: "",
  });
  const currentToastId = useRef("");

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const emaillocation = pathname.split("/")[2]; // Assuming email is the 4th part of the path

  console.log(emaillocation);
  // // Using regular expression:
  // const emailMatch = pathname.match(/\/verify-code\/([^/]+)/);
  // const email = emailMatch ? emailMatch[1] : null;
  const verifyApi = () => {
    setLoader(true);
    axios
      .post(`${Apiurl}/verifyEmail`, {
        email: emaillocation,
        otp: data.otp,
      })

      .then((res) => {
        if (currentToastId.current) {
          toast.dismiss(currentToastId.current);
        }
        setLoader(false);
        navigate(`/new-password/${emaillocation}`);
      })
      .catch((err) => {
        currentToastId.current = toast.error(err?.response?.data?.message, {
          toastId: "loginfailure",
        });
        console.log(err?.response?.data?.message);
        setLoader(false);
        return;
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <section className="signin-sec">
      <div className="inner">
        <div className="inner-content">
          <div className="top-head">
            <h2 className="darkcolor Carmen-bold-font">Verify Code</h2>
            <p className="Carmen-medium-fon greycolor">
              Verification code has been sent to you email
            </p>
          </div>
          <div className="bottom">
            <div className="inputs">
              <Input
                label="Verify Code"
                type="text"
                placeholder="Enter verify code here"
                name="otp"
                onChange={handleChange}
                value={data.otp}
              />

              <Button
                text="Continue"
                className="primary-button w-100 fs-20"
                maxWidth="356px"
                padding="13px"
                onClick={verifyApi}
                disabled={loader ? true : false}
              />
            </div>
          </div>
        </div>
        <div className="back-layer"></div>
      </div>
    </section>
  );
}

export default Verifycode;
