import React, { useEffect, useRef, useState } from "react";
import "./knowledgebase.scss";
import Button from "../../button/button";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Radio, RadioGroup } from "react-radio-group";
import { Icon } from "@iconify/react/dist/iconify.js";
import Inputdash from "../../Inputdash/inputdash";
import axios from "axios";
import { toast } from "react-toastify";
import { getBase64 } from "../../../helperFunctions/convertBase64";

function Knowledgebase() {
  const Apiurl = process.env.REACT_APP_API_KEY;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [allknowledgebases, setAllknowledgebases] = useState([]);
  const [basename, setBasename] = useState("");
  const [basedescription, setBasedescription] = useState("");
  const [datamodal, setDatamodal] = useState(false);
  const [deletemodal, setDeletemodal] = useState(false);
  const [showadd, setShowadd] = useState(false);
  const [content, setContent] = useState(false);
  const [detail, setDetail] = useState(false);
  const [basedocument, setBasedocument] = useState(false);
  const [update, setUpdate] = useState("");
  const [url, setUrl] = useState(false);
  const [agentshow, setAgentshow] = useState(false);
  const agentmodalRef = useRef(null);
  const [filesData, setFilesData] = useState({});

  //  Add data states

  const [dataType, setDataType] = useState("");
  const [dataText, setDataText] = useState("");
  const [dataTextname, setDataTextname] = useState("");
  const [dataUrl, setDataUrl] = useState("");
  const [dataUrlname, setDataUrlname] = useState("");
  const [dataDoc, setDataDoc] = useState("");
  const [dataDocPreview, setDataDocPreview] = useState("");
  const [knowledgebaseContent, setKnowledgebaseContent] = useState([]);
  const [dataDocname, setDataDocname] = useState("");
  const [deleteid, setDeleteid] = useState("");
  const [showData, setShowData] = useState("");
  const [loader, setLoader] = useState(false);
  const [uploadLoader, setUpoadLoader] = useState("");
  const [deleteLoader, setDeleteLoader] = useState("");
  const [deleteBaseLoader, setDeleteBaseLoader] = useState("");
  const [createLoader, setCreateLoader] = useState("");
  const [updateLoader, setUpdateLoader] = useState("");
  const [deleteBasemodal, setDeleteBasemodal] = useState(false);
  const [deletebaseid, setDeletebaseid] = useState(false);

  const modalRef = useRef(null);

  const handleOutsideadd = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowadd(false);
    }
  };
  const handleshowModal = () => {
    setShowadd(!showadd);
  };

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setBasename("");
    setBasedescription("");
    setUpdate("");
  };

  const handleDatammodalshow = () => {
    setDetail(false);
    setDatamodal(true);
  };
  const handleDatammodalclose = () => {
    setDatamodal(false);
    setDetail(true);
    setShowData("");
  };

  const handledeleteshow = () => {
    setDetail(false);
    setDeletemodal(true);
  };
  const handledeleteclose = () => {
    setDeleteid("");
    setDeletemodal(false);
  };
  const handledeleteBaseclose = () => {
    setDeletebaseid("");
    setDeleteBasemodal(false);
  };

  const handleContentshow = () => {
    setDetail(false);
    setContent(true);
  };
  const handleContentclose = () => {
    setContent(false);
    setDetail(true);
    setDataText("");
    setDataTextname("");
  };
  const handleBasedocumentshow = () => {
    setDetail(false);
    setBasedocument(true);
  };
  const handleBasedocumentclose = () => {
    setBasedocument(false);
    setDetail(true);
    setDataDoc("");
    setDataDocname("");
    setDataDocPreview("");
  };
  const handleUrlshow = () => {
    setDetail(false);
    setUrl(true);
  };
  const handleUrlclose = () => {
    setUrl(false);
    setDetail(true);
    setDataUrl("");
  };
  const handleDetailshow = () => setDetail(true);
  const handleDetailclose = () => {
    setDetail(false);
    setUpdate("");
  };

  // Update Function
  // const updateKnowledgebase = () => {
  //   let config = {
  //     method: "post",
  //     maxBodyLength: Infinity,
  //     url: `${Apiurl}/update-knowledgeBase`,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  //     },
  //     data: {
  //       name: basename.name,
  //       description: basedescription.description,
  //     },
  //   };
  //   console.log("Create check", config);

  //   axios
  //     .request(config)

  //     .then((response) => {
  //       toast.success("Updated Successfully!", { toastId: "updated" });
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };
  // Apis
  // Create Api
  const createKnowledgebase = () => {
    setCreateLoader(true);
    if (!basename || !basedescription) {
      toast.error("Please fill in all fields", {
        toastId: "fillfields",
      });
      setCreateLoader(false);
      return;
    } else {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${Apiurl}/create-knowledgeBase`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: {
          name: basename,
          description: basedescription,
        },
      };
      console.log("Create check", config);

      axios
        .request(config)

        .then((res) => {
          console.log(res);
          handleAllknowledgebases();
          if (res.data.success === "expired") {
            toast.error(res.data.message, {
              toastId: "loginsuccess",
            });
            navigate("/");
          }
          toast.success(res.data.message, { toastId: "craeteknowledgebase" });
          setShow(false);
          setCreateLoader(false);
          setBasedescription("");
          setBasename("");
        })
        .catch((err) => {
          toast.error(err, { toastId: "loginfailure" });
          setCreateLoader(false);
          return;
        });
    }
  };
  // Update Knowledge base
  const updateKnowledgebase = () => {
    setUpdateLoader(true);
    if (!basename || !basedescription) {
      toast.error("Please fill in all fields", {
        toastId: "fillfields",
      });
      return;
    } else {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${Apiurl}/update-knowledgeBase/${update}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: {
          name: basename,
          description: basedescription,
        },
      };

      console.log("Create check", config);

      axios
        .request(config)

        .then((res) => {
          console.log(res);
          handleAllknowledgebases();
          if (res.data.success === "expired") {
            toast.error(res.data.message, {
              toastId: "loginsuccess",
            });
            navigate("/");
          }
          toast.success(res.data.message, { toastId: "updateknowledgebase" });
          setBasename("");
          setBasedescription("");
          setShow(false);
          setUpdateLoader(false);
        })
        .catch((err) => {
          toast.error(err, { toastId: "loginfailure" });
          setUpdateLoader(false);
          return;
        });
    }
  };
  // All Knowledgebases Api
  const handleAllknowledgebases = () => {
    setLoader(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Apiurl}/get-all-knowledgebases`,
      // url: `http://localhost:8000/customer/get-all-knowledgebases`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };
    console.log("Create check", config);

    axios
      .request(config)
      .then((res) => {
        setAllknowledgebases(res.data.allKnowledgeBases);
        console.log(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  // Delete Agent
  const deletebaseContent = () => {
    setDeleteLoader(true);
    axios
      .delete(`${Apiurl}/delete-knowledgeBaseContent/${deleteid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((res) => {
        console.log(res);

        toast.success(res.data.message, { toastId: "deleteSuccess" });
        handleAllknowledgebases();
        handledeleteBaseclose();
        handleKnowledgebaseContent();
        setDeleteLoader(false);
        handledeleteclose();
      })
      .catch((err) => {
        toast.error(err.response, { toastId: "deleteerr" });
        setDeleteLoader(false);
      });
  };
  //
  // Delete Base
  const deletebase = () => {
    setDeleteBaseLoader(true);
    axios
      .delete(`${Apiurl}/deleteKnowledgeBase/${deletebaseid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message, { toastId: "deletebaseSuccess" });
        handleAllknowledgebases();
        setDeleteBasemodal(false);

        setDeleteBaseLoader(false);
      })
      .catch((err) => {
        toast.error(err.response, { toastId: "deletebaseerr" });
        setDeleteBaseLoader(false);
      });
  };
  //
  // Create Knowledgebase content..
  const createKnowledgebaseContent = async (type) => {
    if (
      (type == "file" && dataDoc == "") ||
      (type == "file" && dataDocname == "")
    ) {
      toast.error("Please fill all fields", { toastId: "docerr" });
      return;
    } else if (
      (type == "text" && dataText == "") ||
      (type == "text" && dataTextname == "")
    ) {
      toast.error("Please fill all fields", { toastId: "texterr" });
      return;
    }
    setUpoadLoader(true);
    // Ensure these variables are defined and hold valid content before making the request
    if (!dataTextname && !dataDocname && !dataUrlname) {
      console.error(
        "At least one of dataTextname, dataDocname, or dataUrlname must be provided."
      );
      return;
    }
    // Construct the name and content values based on available data
    const name = dataTextname || dataDocname || dataUrlname;
    console.log("dataDoc", dataDoc);

    let contentdata = dataText || dataUrl;
    if (type == "file") {
      if (dataDoc) {
        const fileDetails = {
          file: await getBase64(dataDoc),
          fileName: dataDoc.name,
          fileFolderPath: "knowledgeBaseContent",
          fileType: dataDoc.type,
        };
        contentdata = fileDetails;
      }
    }
    console.log("content", contentdata);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${Apiurl}/create-knowledgeBaseContent`,
      // url: `http://localhost:8000/customer/create-knowledgeBaseContent`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      data: {
        name: name,
        content: contentdata,
        type: type,
        knowledgeBaseId: update,
      },
    };
    axios
      .request(config)
      .then((res) => {
        console.log(res);
        setBasedocument(false);
        setContent(false);
        setUrl(false);
        setUpdate("");
        setDataDoc("");
        setDataUrl("");
        setDataTextname("");
        setDataText("");
        setDataUrlname("");
        setDataDocname("");
        handleAllknowledgebases();
        toast.success(res.data.message, { toastId: "createKnowledgeBase" });
        setUpoadLoader(false);
        setDataDocPreview("");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.response?.error?.message, {
          toastId: "createKnowledgeBaseError",
        });
        console.error("Error occurred: ", err);
        setUpoadLoader(false);
      });
  };
  // Get Knowledgebasecontent
  const handleKnowledgebaseContent = (id) => {
    console.log("ID", id);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Apiurl}/getKnowledgeBaseContent/${id}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };
    console.log("Create check", config);

    axios
      .request(config)
      .then((res) => {
        console.log(res.data);
        setKnowledgebaseContent(res.data.data);
        setDetail(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBasedatachange = (e) => {
    setBasename(e.target.value);
  };
  const handleBasedescchange = (e) => {
    setBasedescription(e.target.value);
  };

  useEffect(() => {
    handleAllknowledgebases();
    document.addEventListener("mousedown", handleOutsideadd);
    return () => {
      document.removeEventListener("mousedown", handleOutsideadd);
    };
  }, []);

  const testRef = useRef(null);
  return (
    <div className="dash-main">
      <section className="knowledgebase-sec">
        <div className="head">
          <h3 className="heading primarycolor Carmen-semibold-font">
            Create New Knowledge Base
          </h3>
          <Button
            text="Create"
            className="primary-button w-100 fs-16 "
            maxWidth="94px"
            padding="10px 18px"
            onClick={handleShow}
          />
        </div>

        {loader ? (
          <div className="spinner-outer">
            <span className="spinner"></span>
          </div>
        ) : (
          <div className="knowledgebase-cards">
            {allknowledgebases && allknowledgebases.length > 0 ? (
              allknowledgebases.map((item, index) => (
                <div
                  className="knowledgebase-card"
                  key={item._id}
                  id={item?.customerId}
                >
                  <div className="card-head">
                    <h4 className="Carmen-bold-font darkcolor">{item.name}</h4>
                    <div className="d-flex align-items-center gap-2">
                      <div className="bound">
                        <p className="Carmen-bold-font secondarycolor">
                          Total Files : {item.contentsCount}
                        </p>
                        <div className="bound-layer"></div>
                      </div>
                      <img
                        src="/assets/icons/delete.svg"
                        alt="..."
                        style={{
                          width: "30px",
                          height: "30px",
                          objectFit: "cover",
                        }}
                        className="pointer"
                        onClick={() => {
                          setDeleteBasemodal(true);
                          setDeletebaseid(item._id);
                        }}
                      />
                    </div>
                  </div>
                  <h5 className="Carmen-regular-font greycolor">
                    {item.description}
                  </h5>
                  <div className="d-flex justify-content-between">
                    <Button
                      text="Add Data"
                      className="primary-button w-100 fs-16"
                      maxWidth="115px"
                      padding="10px 16px"
                      onClick={() => {
                        setUpdate(item._id);
                        handleKnowledgebaseContent(item._id);
                      }}
                    />
                    <Button
                      text="Update"
                      className="primary-button w-100 fs-16 "
                      maxWidth="94px"
                      padding="10px 18px"
                      onClick={() => {
                        handleShow();
                        setBasename(item.name);
                        setBasedescription(item.description);
                        setUpdate(item._id);
                      }}
                    />
                    {/* <Button
                  text="Edit"
                  className="primary-button w-100 fs-16"
                  maxWidth="115px"
                  padding="10px 16px"
                  onClick={updateKnowledgebase}
                /> */}
                  </div>
                </div>
              ))
            ) : (
              <p>No Knowledge base found.</p>
            )}
          </div>
        )}
        <Modal
          show={show}
          onHide={handleClose}
          centered
          className="knowledgebase-modal"
        >
          <Modal.Body>
            <img
              src="/assets/icons/modalclose.svg"
              alt="..."
              className="pointer close-btn"
              onClick={handleClose}
            />
            <h2 className="Carmen-semibold-font primarycolor">
              Knowledge Base
            </h2>
            <div className="modal-inner">
              <Inputdash
                type="text"
                label="Name"
                placeholder="James"
                name="name"
                state={basename}
                setState={setBasename}
                value={basename}
                onchange={handleBasedatachange}
              />
              <Inputdash
                label="Description"
                placeholder="Lorem Ipsum"
                name="description"
                state={basedescription}
                value={basedescription}
                setState={setBasedescription}
                onchange={handleBasedescchange}
              />
              {!update ? (
                <Button
                  text="Create"
                  className="primary-button w-100 fs-16 "
                  maxWidth="131px"
                  padding="10px 16px"
                  onClick={createKnowledgebase}
                  disabled={createLoader ? true : false}
                />
              ) : (
                <Button
                  text="Update"
                  className="primary-button w-100 fs-16 "
                  maxWidth="131px"
                  padding="10px 16px"
                  onClick={updateKnowledgebase}
                  disabled={updateLoader ? true : false}
                />
              )}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={basedocument}
          onHide={handleBasedocumentclose}
          centered
          className="knowledgebase-modal"
        >
          <Modal.Body>
            <img
              src="/assets/icons/modalclose.svg"
              alt="..."
              className="pointer close-btn"
              onClick={handleBasedocumentclose}
            />
            <h2 className="Carmen-semibold-font primarycolor">
              Knowledge Base
            </h2>
            <div className="modal-inner">
              <Inputdash
                type="text"
                label="Name"
                placeholder="James"
                value={dataDocname}
                name="dataDocname"
                onchange={(e) => {
                  setDataDocname(e.target.value);
                }}
              />
              <div className="upload-doc">
                <h5 className="darkcolor Carmen-semibold-font">
                  Upload Document
                </h5>
                <label htmlFor="documentfile">
                  <input
                    type="file"
                    id="documentfile"
                    className="d-none"
                    name="dataDoc"
                    accept="application/pdf"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        setDataDoc(file);
                        setDataDocPreview(URL.createObjectURL(file));
                      }
                    }}
                  />
                  {dataDocPreview ? (
                    <embed
                      src={dataDocPreview}
                      className="w-100 h-100 customscrollbar"
                      style={{ maxHeight: "150px", objectFit: "contain" }}
                      type="application/pdf"
                    ></embed>
                  ) : (
                    <h4
                      className="Carmen-regular-font greycolor"
                      style={{ opacity: "0.45" }}
                    >
                      Browse
                    </h4>
                  )}
                </label>
              </div>

              <Button
                text="Upload"
                className="primary-button w-100 fs-16 "
                maxWidth="131px"
                padding="10px 16px"
                onClick={() => createKnowledgebaseContent("file")}
                disabled={uploadLoader ? true : false}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/* <Modal
          show={content}
          onHide={handleContentclose}
          centered
          className="knowledgebase-modal"
        >
          <Modal.Body>
            <img
              src="/assets/icons/modalclose.svg"
              alt="..."
              className="pointer close-btn"
              onClick={handleContentclose}
            />
            <h2 className="Carmen-semibold-font primarycolor">
              Knowledge Base
            </h2>
            <div className="modal-inner">
              <Inputdash
                type="text"
                label={allknowledgebases.name}
                placeholder="James"
                value={allknowledgebases.name}
              />
              <Inputdash
                name="dataText"
                value={dataText}
                label="Upload Content"
                onchange={(e) => {
                  setDataText(e.target.value);
                }}
                placeholder="Lorem ipsum dolor sit amet consectetur. Tincidunt tristique pretium est congue massa praesent eget."
              />
              <Button
                text="Upload"
                className="primary-button w-100 fs-16 "
                maxWidth="131px"
                padding="10px 16px"
                onClick={handleContentclose}
              />
            </div>
          </Modal.Body>
        </Modal> */}
        {/* <Modal
          show={url}
          onHide={handleUrlclose}
          centered
          className="knowledgebase-modal"
        >
          <Modal.Body>
            <img
              src="/assets/icons/modalclose.svg"
              alt="..."
              className="pointer close-btn"
              onClick={handleUrlclose}
            />
            <h2 className="Carmen-semibold-font primarycolor">
              Knowledge Base
            </h2>
            <div className="modal-inner">
              <Inputdash
                type="text"
                label="Name"
                placeholder="James"
                value={dataUrlname}
                name="dataUrlname"
                onchange={(e) => {
                  setDataUrlname(e.target.value);
                }}
              />
              <Inputdash
                type="text"
                label="Paste Link"
                placeholder="Enter Url"
                value={dataUrl}
                name="dataUrl"
                onchange={(e) => {
                  setDataUrl(e.target.value);
                }}
              />

              <Button
                text="Upload"
                className="primary-button w-100 fs-16 "
                maxWidth="131px"
                padding="10px 16px"
                onClick={() => createKnowledgebaseContent("Url")}
              />
            </div>
          </Modal.Body>
        </Modal> */}
        {/* Detail Modal */}
        <Modal
          show={detail}
          onHide={handleDetailclose}
          centered
          className="detail-modal"
        >
          <Modal.Body>
            <div className="detailModal-inner">
              <div className="top">
                <Icon
                  icon="weui:back-filled"
                  width="24"
                  height="24"
                  style={{ color: "#202020" }}
                  onClick={handleDetailclose}
                  className="pointer"
                />
                <h2 className="Carmen-semibold-font primarycolor">
                  Knowledge Base Content
                </h2>
                <div
                  className="pointer add-div"
                  onClick={handleshowModal}
                  ref={modalRef}
                >
                  <h5 className="Carmen-semibold-font whitecolor">Add</h5>
                  {showadd ? (
                    <Icon icon="mingcute:up-line" style={{ color: "white" }} />
                  ) : (
                    <Icon
                      icon="mingcute:down-line"
                      style={{ color: "white" }}
                    />
                  )}
                  <div
                    className={`model-content ${
                      showadd === false ? "modal-hide" : "modal-show"
                    }`}
                  >
                    <p
                      className="Carmen-regular-font greycolor"
                      onClick={handleBasedocumentshow}
                    >
                      Upload Document
                    </p>
                    {/* <p
                      className="Carmen-regular-font greycolor"
                      onClick={handleUrlshow}
                    >
                      Add Url
                    </p> */}
                    <p
                      className="Carmen-regular-font greycolor"
                      onClick={handleContentshow}
                    >
                      Text
                    </p>
                  </div>
                </div>
              </div>
              <div className="bottom">
                <div className="table-outer">
                  <div className="table-head">
                    <div className="first">
                      <p className="darkcolor Carmen-medium-font">Name</p>
                    </div>
                    <div className="second">
                      <p className="darkcolor Carmen-medium-font">Content </p>
                    </div>
                    <div className="third">
                      <p className="darkcolor Carmen-medium-font">Actions </p>
                    </div>
                  </div>
                  <div className="table-content">
                    {knowledgebaseContent.length < 1 ? (
                      <p className="small-para greycolor Carmen-regular-font text-center">
                        No Data
                      </p>
                    ) : (
                      knowledgebaseContent.map((item, index) => (
                        <div className="table-detrow" key={item._id}>
                          <div className="first">
                            <p className="small-para greycolor Carmen-regular-font">
                              {item.name.length > 25
                                ? `${item.name.slice(0, 25)}...`
                                : item.name}
                            </p>
                          </div>
                          <div className="second">
                            {item.type == "text" ? (
                              <>
                                <img src="/assets/icons/text.svg" alt="..." />
                                <p className="small-para greycolor Carmen-regular-font">
                                  {item.content.length > 50
                                    ? `${item.content.slice(0, 50)}...`
                                    : item.content}
                                </p>
                              </>
                            ) : (
                              <>
                                <img src="/assets/icons/pdf.svg" alt="..." />
                                <p className="small-para greycolor Carmen-regular-font">
                                  Pdf
                                </p>
                              </>
                            )}
                          </div>
                          <div className="third">
                            {item.type == "text" ? (
                              <img
                                src="/assets/icons/view.svg"
                                alt="..."
                                className="pointer"
                                onClick={() => {
                                  handleDatammodalshow();
                                  setShowData(item.content);
                                }}
                              />
                            ) : (
                              <a href={item.content} target="_blank">
                                <img
                                  src="/assets/icons/view.svg"
                                  alt="..."
                                  className="pointer"
                                  // onClick={handleDatammodalshow}
                                />
                              </a>
                            )}
                            <img
                              src="/assets/icons/delete.svg"
                              alt="..."
                              className="pointer"
                              onClick={() => {
                                setDeleteid(item._id);
                                handledeleteshow();
                              }}
                            />
                          </div>
                        </div>
                      ))
                    )}

                    {/* 
                    <div className="table-detrow ">
                      <div className="first">
                        <p className="small-para greycolor Carmen-regular-font">
                          Welcome Message
                        </p>
                      </div>
                      <div className="second">
                        <p className="small-para greycolor Carmen-regular-font">
                          <img src="/assets/icons/pdf.svg" alt="..." /> Lorem
                          ipsum dolor sit amet, sit amet...
                        </p>
                      </div>
                      <div className="third">
                        <img
                          src="/assets/icons/view.svg"
                          className="pointer"
                          alt="..."
                        />
                        <img
                          src="/assets/icons/delete.svg"
                          className="pointer"
                          alt="..."
                        />
                      </div>
                    </div> */}
                    {/* <div className="table-detrow ">
                      <div className="first">
                        <p className="small-para greycolor Carmen-regular-font">
                          Welcome Message
                        </p>
                      </div>
                      <div className="second">
                        <p className="small-para greycolor Carmen-regular-font">
                          <img src="/assets/icons/link.svg" alt="..." /> Lorem
                          ipsum dolor sit amet, sit amet...
                        </p>
                      </div>
                      <div className="third">
                        <img
                          src="/assets/icons/view.svg"
                          className="pointer"
                          alt="..."
                        />
                        <img
                          src="/assets/icons/delete.svg"
                          className="pointer"
                          alt="..."
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Delete Modal */}

        <Modal
          show={deletemodal}
          onHide={handledeleteclose}
          centered
          className="delete-modal"
        >
          <Modal.Body>
            <img
              src="/assets/icons/modalclose.svg"
              alt="..."
              className="pointer close-btn"
              onClick={handledeleteclose}
            />
            <h3 className="Carmen-semibold-font primarycolor text-center">
              Are you sure you want to delete?
            </h3>
            <div>
              <Button
                text="Yes"
                className="secondary-btn fs-16 mx-auto"
                width="93px"
                padding="8px 24px"
                onClick={deletebaseContent}
                disabled={deleteLoader ? true : false}
              />
              <Button
                text="No"
                className="primary-button fs-16 mx-auto"
                width="93px"
                padding="8px 24px"
                onClick={handledeleteclose}
              />
            </div>
          </Modal.Body>
        </Modal>

        {/* Delete Base Modal */}

        <Modal
          show={deleteBasemodal}
          onHide={handledeleteBaseclose}
          centered
          className="delete-modal"
        >
          <Modal.Body>
            <img
              src="/assets/icons/modalclose.svg"
              alt="..."
              className="pointer close-btn"
              onClick={handledeleteBaseclose}
            />
            <h3 className="Carmen-semibold-font primarycolor text-center">
              Are you sure you want to delete?
            </h3>
            <div>
              <Button
                text="Yes"
                className="secondary-btn fs-16 mx-auto"
                width="93px"
                padding="8px 24px"
                onClick={deletebase}
                disabled={deleteBaseLoader ? true : false}
              />
              <Button
                text="No"
                className="primary-button fs-16 mx-auto"
                width="93px"
                padding="8px 24px"
                onClick={handledeleteBaseclose}
              />
            </div>
          </Modal.Body>
        </Modal>

        {/* Contentmodal */}

        <Modal
          show={content}
          onHide={handleContentclose}
          centered
          className="knowledgebase-modal"
        >
          <Modal.Body>
            <img
              src="/assets/icons/modalclose.svg"
              alt="..."
              className="pointer close-btn"
              onClick={handleContentclose}
            />
            <h2 className="Carmen-semibold-font primarycolor">
              Knowledge Base
            </h2>
            <div className="modal-inner">
              <Inputdash
                type="text"
                label="Name"
                placeholder="James"
                name="dataTextname"
                value={dataTextname}
                onchange={(e) => {
                  setDataTextname(e.target.value);
                }}
              />
              <Inputdash
                label="Upload Content"
                name="dataText"
                value={dataText}
                onchange={(e) => {
                  setDataText(e.target.value);
                }}
                placeholder="Lorem ipsum dolor sit amet consectetur. Tincidunt tristique pretium est congue massa praesent eget."
              />
              <Button
                text="Upload"
                className="primary-button w-100 fs-16 "
                maxWidth="131px"
                padding="10px 16px"
                onClick={() => createKnowledgebaseContent("text")}
                disabled={uploadLoader ? true : false}
              />
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={datamodal}
          onHide={handleDatammodalclose}
          centered
          className="content-modal"
        >
          <Modal.Body>
            <img
              src="/assets/icons/modalclose.svg"
              alt="..."
              className="pointer close-btn"
              onClick={handleDatammodalclose}
            />
            <h2 className="Carmen-semibold-font primarycolor">Content</h2>
            <div className="modal-inner w-100">
              <h5 className="Carmen-regular-font greycolor ">{showData}</h5>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </div>
  );
}

export default Knowledgebase;
