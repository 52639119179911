import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../sidebar/sidebar";
import Header from "../header/header";
import axios from "axios";
import { UserHubspotContext } from "../../App";

const Dashboard = () => {
  const { hubspotData, setHubspotData } = useContext(UserHubspotContext);
  const [show, setShow] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const Apiurl = process.env.REACT_APP_API_KEY;
  const logintoken = localStorage.getItem("authToken");
  const tokenverify = () => {
    axios
      .get(`${Apiurl}/tokenVerification`, {
        headers: {
          Authorization: `Bearer ${logintoken}`,
        },
      })
      .then((response) => {
        setHubspotData(response?.data?.User?.hubspotkey);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (location.state == "goToSettings") {
      navigate("/settings", { state: "connect" });
      setFirstTime(false);
      return;
    }
    if (firstTime) {
      if (location.pathname != "/dashboard") {
        navigate("/dashboard");
        setFirstTime(false);
      }
    }
    tokenverify();
  }, []);

  return (
    <>
      <div className="Dashboard-main-container d-flex overflow-x-hidden">
        <div className="left-content">
          <Sidebar show={show} setShow={setShow} />
        </div>

        <div className="right_content w-100">
          <Header show={show} setShow={setShow} />
          <div className="page-dom">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
