import React, { useContext, useState } from "react";
import "./profileinput.scss";
import { Icon } from "@iconify/react/dist/iconify.js";
import { LoginContext } from "../../App";
function Profileinput({
  placeholder,
  label,
  type,
  value,
  disabled,
  name,
  onchange,
}) {
  const [show, setShow] = useState(true);
  const showpassword = () => {
    setShow(!show);
  };

  return (
    <section className="profileinput-sec">
      <h5 className="Carmen-semibold-font darkcolor">{label}</h5>
      <div className="input-div">
        <input
          type={show ? "text" : type}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          name={name}
          onChange={onchange}
        />
        {type === "password" && (
          <>
            {show ? (
              <Icon
                icon="mdi:eye"
                style={{ color: "#A9A9A9" }}
                onClick={showpassword}
                className="pointer"
              />
            ) : (
              <Icon
                icon="mdi:eye-off"
                style={{ color: "#A9A9A9" }}
                onClick={showpassword}
                className="pointer"
              />
            )}
          </>
        )}
      </div>
    </section>
  );
}

export default Profileinput;
