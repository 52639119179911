import React from "react";
import Lottie from "lottie-react";
import animationData from "../../../src/assets/Animation - 1720783289470.json";
import { NavLink } from "react-browser-router";
import { useNavigate } from "react-router-dom";
function Connect() {
  const navigate = useNavigate();

  return (
    <section className="signin-sec">
      <div className="inner">
        <div className="connect-inner">
          <h2 className="darkcolor Carmen-bold-font">Connect to HubSpot</h2>
          <div className="connect-inner-bottom">
            <div
              className="gif-div pointer "
              onClick={() => {
                navigate("/dashboard", { state: "goToSettings" });
              }}
            >
              <div className="connect-circle">
                <h2 className="Carmen-semibold-font primarycolor">Connect</h2>
                <img
                  src="/assets/gifs/dotsgif.gif"
                  alt="..."
                  className="dots-gif"
                />
              </div>

              <Lottie
                animationData={animationData}
                className="main-gif"
                loop={true}
              />
            </div>
            <NavLink
              to="/dashboard"
              className="forget-link greycolor Carmen-regular-font"
            >
              Skip
            </NavLink>
          </div>
        </div>
        <div className="back-layer"></div>
      </div>
    </section>
  );
}

export default Connect;
