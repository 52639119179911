import React, { useRef, useState } from "react";
import Input from "../input/input";
import Button from "../button/button";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
function Newpassword() {
  const Apiurl = process.env.REACT_APP_API_KEY;
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    password: "",
    confirm_password: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const emaillocation = pathname.split("/")[2];
  const currentToastId = useRef("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const newpasswordApi = () => {
    setLoader(true);
    axios
      .post(`${Apiurl}/forgotPassword`, {
        email: emaillocation,
        password: data.password,
        confirm_password: data.confirm_password,
      })

      .then((res) => {
        if (currentToastId.current) {
          toast.dismiss(currentToastId.current);
        }
        toast.success("Password Changed Successfully", {
          toastId: "loginsuccess",
        });
        setLoader(false);
        navigate("/");
      })
      .catch((err) => {
        currentToastId.current = toast.error(err?.response?.data?.error, {
          toastId: "loginfailure",
        });
        setLoader(false);
        console.log(err?.response?.data?.error);
        return;
      });
  };

  return (
    <section className="signin-sec">
      <div className="inner">
        <div className="inner-content">
          <div className="top-head">
            <h2 className="darkcolor Carmen-bold-font">Set New Password</h2>
          </div>
          <div className="bottom">
            <div className="inputs">
              <Input
                label="New Password"
                type="password"
                placeholder="Enter new password"
                onChange={handleChange}
                name="password"
                value={data.password}
              />
              <Input
                label="Confirm Password"
                type="password"
                placeholder="Confirm password"
                onChange={handleChange}
                name="confirm_password"
                value={data.confirm_password}
              />

              <Button
                text="Continue"
                className="primary-button w-100 fs-20"
                maxWidth="356px"
                padding="13px"
                onClick={newpasswordApi}
                disabled={loader ? true : false}
              />
            </div>
          </div>
        </div>
        <div className="back-layer"></div>
      </div>
    </section>
  );
}

export default Newpassword;
