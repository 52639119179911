import React, { useState } from "react";
import "./remindersHistory.scss";
import Inputdash from "../Inputdash/inputdash";
import { Modal } from "react-bootstrap";
import Button from "../button/button";
function RemindersHistory() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const data = [
    {
      note: "Hello! Thank you for calling [Company Name]. This is [Agent Name], your AI assistant. How can I assist you today with your business loan inquiry?Lorem ipsum dolor sit amet consectetur. Molestie ornare egestas convallis quisque facilisi. Quam morbi nisi quam quis. Vestibulum turpis sollicitudin nulla ",
      list: "Lorem ipsum dolor sit amet consectetur. ",
      time: "07/12/2024 11:49am",
    },
    {
      note: "Hello! Thank you for calling [Company Name]. This is [Agent Name], your AI assistant. How can I assist you today with your business loan inquiry?Lorem ipsum dolor sit amet consectetur. Molestie ornare egestas convallis quisque facilisi. Quam morbi nisi quam quis. Vestibulum turpis sollicitudin nulla ",
      list: "Lorem ipsum dolor sit amet consectetur. ",
      time: "07/12/2024 11:49am",
    },
    {
      note: "Hello! Thank you for calling [Company Name]. This is [Agent Name], your AI assistant. How can I assist you today with your business loan inquiry?Lorem ipsum dolor sit amet consectetur. Molestie ornare egestas convallis quisque facilisi. Quam morbi nisi quam quis. Vestibulum turpis sollicitudin nulla ",
      list: "Lorem ipsum dolor sit amet consectetur. ",
      time: "07/12/2024 11:49am",
    },
  ];
  return (
    <div className="dash-main">
      <section className="remindersHistory-sec customscrollbar">
        <h3 className="Carmen-bold-font primarycolor">Reminders History </h3>
        <div className="reminderhistory-divs">
          {data.map((item, index) => (
            <div className="history" id="index">
              <div className="icon-div">
                <img
                  src="/assets/icons/deletehistory.svg"
                  alt="..."
                  className="delete-icon pointer"
                  onClick={handleShow}
                />
                <img
                  src="/assets/icons/resend.svg"
                  alt="..."
                  className="resend-icon pointer"
                />
              </div>
              <Inputdash label="Reminding Note" value={item.note} />
              <Inputdash label="Sent to this contact list" value={item.list} />
              <p className="small Carmen-regular-font darkcolor">{item.time}</p>
            </div>
          ))}
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          centered
          className="delete-modal"
        >
          <Modal.Body>
            <img
              src="/assets/icons/modalclose.svg"
              alt="..."
              className="pointer close-btn"
              onClick={handleClose}
            />
            <h3 className="Carmen-semibold-font primarycolor text-center">
              Are you sure you want to delete?
            </h3>
            <div>
              <Button
                text="Yes"
                className="secondary-btn fs-16 mx-auto"
                width="93px"
                padding="8px 24px"
                onClick={handleClose}
              />
              <Button
                text="No"
                className="primary-button fs-16 mx-auto"
                width="93px"
                padding="8px 24px"
                onClick={handleClose}
              />
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </div>
  );
}

export default RemindersHistory;
