import React, { useEffect, useRef, useState } from "react";
import "./reminders.scss";
import { Icon } from "@iconify/react/dist/iconify.js";
import Inputdash from "../Inputdash/inputdash";
import Button from "../button/button";
import { Modal } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";
function Reminders() {
  const navigate = useNavigate();
  const [selectcontact, setSelectcontact] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const modalRef = useRef(null);
  const handleSelectcontact = () => {
    setSelectcontact(!selectcontact);
  };
  const handleOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      // if (event.target.contains(modalRef.current)) {
      setSelectcontact(false);
      // }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutside);
    return () => {
      document.removeEventListener("mousedown", handleOutside);
    };
  }, []);
  const data = [
    {
      contactList: "An Board Contacts Researched - Condomini...drew",
    },
    {
      contactList: "An Board Contacts Researched - Condomini...drew",
    },
    {
      contactList: "An Board Contacts Researched - Condomini...drew",
    },
    {
      contactList: "An Board Contacts Researched - Condomini...drew",
    },
    {
      contactList: "An Board Contacts Researched - Condomini...drew",
    },
    {
      contactList: "An Board Contacts Researched - Condomini...drew",
    },
    {
      contactList: "An Board Contacts Researched - Condomini...drew",
    },
    {
      contactList: "An Board Contacts Researched - Condomini...drew",
    },
  ];
  return (
    <div className="dash-main">
      <section className="reminders-sec">
        <img
          src="/assets/icons/clock.svg"
          alt="..."
          className="reminder-img pointer"
          onClick={() => {
            navigate("/reminders-history");
          }}
        />
        <h3 className="Carmen-bold-font primarycolor">Remind User</h3>
        <div className="reminder-content">
          <div className="top" ref={modalRef}>
            <h5 className="darkcolor Carmen-semibold-font">
              Select Contact List
            </h5>
            <div className="select-agent ">
              <div
                className="select-inner pointer"
                onClick={handleSelectcontact}
              >
                <h4 className="greycolor Carmen-regular-font">All Contacts</h4>
                {!selectcontact ? (
                  <Icon icon="mingcute:down-line" />
                ) : (
                  <Icon icon="mingcute:up-line" />
                )}
              </div>
              {selectcontact && (
                <div className="select-options">
                  <div className="search-bar">
                    <img
                      src="/assets/icons/search.svg"
                      alt="..."
                      className="pointer"
                    />
                    <input type="text" placeholder="Search here" />
                  </div>
                  <p className="greycolor Carmen-regular-font">Lists</p>

                  <div className="agents customscrollbar">
                    {data.map((item, index) => (
                      <h5
                        className="greycolor Carmen-regular-font"
                        onClick={() => {
                          setSelectcontact(false);
                        }}
                      >
                        {item.contactList}
                      </h5>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="bottom">
            <Inputdash
              label="Reminding Note"
              value="Hello! Thank you for calling [Company Name]. This is [Agent Name], your AI assistant. How can I assist you today with your business loan inquiry?Lorem ipsum dolor sit amet consectetur. Molestie ornare egestas convallis quisque facilisi. Quam morbi nisi quam quis. Vestibulum turpis sollicitudin nulla "
            />
          </div>
        </div>
        <div className="buttons-div">
          <Button
            text="Discard"
            className="secondary-btn w-100 fs-16"
            maxWidth="157px"
            padding="12px 36px"
          />

          <Button
            text="Send"
            className="primary-button w-100 fs-16 "
            maxWidth="157px"
            padding="12px 36px"
            onClick={handleShow}
          />
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          centered
          className="advance-save"
        >
          <Modal.Body>
            <img src="/assets/icons/congrats.svg" alt="..." />
            <div>
              <h4 className="Carmen-semibold-font primarycolor">
                Congratulations
              </h4>
              <h4 className="Carmen-bold-font secondarycolor">
                Reminder Sent Successfully
              </h4>
            </div>
            <Button
              text="Finish"
              className="primary-button w-100 fs-20 mx-auto"
              maxWidth="207px"
              padding="12px 36px"
              onClick={handleClose}
            />
          </Modal.Body>
        </Modal>
      </section>
    </div>
  );
}

export default Reminders;
