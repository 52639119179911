import axios from "axios";

export const AllNumbers = async () => {
  return new Promise((resolve, reject) => {
    const TWILIO_ACCOUNT_SID = "ACb43c8c6cc6de5931c1b1de37449cae97";
    const TWILIO_ACCOUNT_Token = "3e08ce6776ab8e4f6d29c67b4d729f64";

    axios
      .get(
        `https://api.twilio.com/2010-04-01/Accounts/${TWILIO_ACCOUNT_SID}/IncomingPhoneNumbers.json`,
        {
          params: {
            PageSize: 20,
          },
          auth: {
            username: TWILIO_ACCOUNT_SID,
            password: TWILIO_ACCOUNT_Token,
          },
        }
      )
      .then((response) => {
        console.log(response.data.incoming_phone_numbers);
        resolve(response.data.incoming_phone_numbers);
      })
      .catch((error) => {
        console.error("Error fetching incoming phone numbers:", error);
        reject([]);
      });
  });
};
