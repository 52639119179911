import React, { useContext, useEffect, useState } from "react";
import "./settings.scss";
import Profileinput from "../profileinput/profileinput";
import Button from "../button/button";
import axios from "axios";
import { toast } from "react-toastify";
import {
  LoginContext,
  UserHubspotContext,
  UserImageContext,
  UserNameContext,
} from "../../App";
import { useLocation, useNavigate } from "react-router-dom";

function Settings() {
  const navigate = useNavigate();
  const Apiurl = process.env.REACT_APP_API_KEY;
  const HubspotUrl = process.env.REACT_APP_AWS_BASE_URL;
  const logintoken = localStorage.getItem("authToken");

  const [loader, setLoader] = useState(false);
  const [profileLoader, setProfileLoader] = useState(false);
  const [passwordLoader, setPasswordLoader] = useState(false);
  const [hubspotLoader, setHubspotLoader] = useState(false);
  const [active, setActive] = useState("profile");
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { loginStatus, setLoginStatus } = useContext(LoginContext);
  const { userName, setUserName } = useContext(UserNameContext);
  const { imgData, setImgData } = useContext(UserImageContext);
  const [name, setName] = useState(userName);
  const [hubspot, setHubspot] = useState("");
  const { hubspotData, setHubspotData } = useContext(UserHubspotContext);
  const [img, setImage] = useState(imgData);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true); // State to control save button
  const [isDiscardDisabled, setIsDiscardDisabled] = useState(true); // New state for Discard button
  const location = useLocation();
  const handleActive = (e) => {
    setActive(e);
  };

  //  Update user
  const handleUpdateProfile = () => {
    if (img === "" || name === "") {
      toast.error("Please fill all fields!", { toastId: "emptyprofile" });
    } else {
      setProfileLoader(true);
      axios
        .post(
          `${Apiurl}/updateCustomer`,
          {
            Image: img,
            firstName: name,
            email: email,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${logintoken}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          tokenverify();
          toast.success(response?.data?.message, {
            toastId: "updatedetailsuccess",
          });
          setProfileLoader(false);
        })
        .catch((error) => {
          console.log(error);
          setProfileLoader(false);
        });
    }
  };
  const handleCheckHubspot = () => {
    setHubspotLoader(true);
    axios
      .get(`${HubspotUrl}/check-hubspot-token?api_token=${hubspot}`, {
        headers: {
          Authorization: `Bearer ${logintoken}`,
        },
      })
      .then((response) => {
        handleConnectHubspot();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.detail, { toastId: "checkerr" });
        setHubspotLoader(false);
      });
  };
  const handleConnectHubspot = () => {
    setHubspotLoader(true);
    axios
      .post(
        `${Apiurl}/updateCustomer`,
        {
          hubspot: hubspot,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${logintoken}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        tokenverify();

        toast.success("Connected Successfully!", {
          toastId: "connectsuccess",
        });
        setHubspotLoader(false);
        setHubspot("");
      })
      .catch((error) => {
        console.log(error);
        setHubspotLoader(false);
        setHubspot("");
      });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Image = reader.result;
      console.log(reader.result);
      setImage(base64Image);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const setPassword = () => {
    if (oldPassword === "" || newPassword === "" || confirmPassword === "") {
      toast.error("Please fill all fields!", { toastId: "emptypassword" });
    } else {
      setPasswordLoader(true);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${Apiurl}/changePassword`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        data: {
          old_password: oldPassword,
          new_password: newPassword,
          confirm_password: confirmPassword,
        },
      };

      console.log("Create check", config);

      axios
        .request(config)
        .then((res) => {
          console.log(res);
          toast.success(res?.data?.message, { toastId: "success changed" });
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setPasswordLoader(false);
          setIsDiscardDisabled(true); // Reset discard button state after successful password change
        })
        .catch((err) => {
          console.log(err?.response?.data?.message);
          toast.error(err?.response?.data?.message, { toastId: "err" });
          setPasswordLoader(false);
          return;
        });
    }
  };

  const tokenverify = () => {
    console.log(logintoken);
    setLoader(true);
    axios
      .get(`${Apiurl}/tokenVerification`, {
        headers: {
          Authorization: `Bearer ${logintoken}`,
        },
      })
      .then((response) => {
        console.log(response);
        setEmail(response?.data?.User?.email);
        setUserName(response?.data?.User?.firstName);
        setImgData(response?.data?.User?.Image);
        setHubspotData(response?.data?.User?.hubspotkey);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("User Expired", { toastId: "usertoast" });
        setLoader(false);
        localStorage.clear();
        navigate("/");
      });
  };

  // Enable save button only when name or image is changed
  useEffect(() => {
    if (location.state == "connect") {
      setActive(location.state);
    }

    if (name !== userName || img !== imgData) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [name, img, userName, imgData]);

  // Enable discard button only when password fields are modified
  useEffect(() => {
    if (oldPassword || newPassword || confirmPassword) {
      setIsDiscardDisabled(false);
    } else {
      setIsDiscardDisabled(true);
    }
  }, [oldPassword, newPassword, confirmPassword]);

  useEffect(() => {
    console.log(loginStatus);
    tokenverify();
  }, []);

  return (
    <div className="dash-main">
      <section className="settings-sec">
        <div className="settings-tabs">
          <div className="tabs-inner">
            <h5
              className={`Carmen-medium-font ${
                active === "profile" ? "active" : ""
              }`}
              onClick={() => handleActive("profile")}
            >
              Profile settings
            </h5>
            {!loginStatus ? (
              <h5
                className={`Carmen-medium-font ${
                  active === "password" ? "active" : ""
                }`}
                onClick={() => handleActive("password")}
              >
                Change password
              </h5>
            ) : (
              ""
            )}

            <h5
              className={`Carmen-medium-font ${
                active === "connect" ? "active" : ""
              }`}
              onClick={() => handleActive("connect")}
            >
              Connect to Hubspot
            </h5>
          </div>
        </div>
        {active === "profile" ? (
          loader ? (
            <div className="spinner-outer">
              <span className="spinner"></span>
            </div>
          ) : (
            <div className="profile-content">
              <h3 className="Carmen-bold-font primarycolor">
                Setup your profile
              </h3>
              <div className="content-inner">
                <div className="left">
                  <input
                    type="file"
                    id="profileImage"
                    className="d-none"
                    onChange={handleImageUpload}
                    accept="image/*"
                  />
                  <label htmlFor="profileImage" className="pointer">
                    {img ? (
                      <img src={img} alt="..." />
                    ) : imgData ? (
                      <img src={imgData} alt="..." />
                    ) : (
                      <img src="/assets/images/user.jpg" alt="..." />
                    )}
                    <h5 className="Carmen-semibold-font darkcolor">
                      Upload your image
                    </h5>
                  </label>
                </div>
                <div className="right">
                  <Profileinput
                    type="text"
                    label="User Name"
                    name="name"
                    value={name}
                    onchange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <Profileinput
                    type="email"
                    label="Email"
                    value={email}
                    disabled="true"
                  />
                </div>
              </div>
              <div className="bottom">
                <Button
                  text="Save Changes"
                  className="primary-button w-100 fs-16 ms-auto"
                  maxWidth="157px"
                  padding="12px 18px"
                  onClick={handleUpdateProfile}
                  disabled={profileLoader ? true : isSaveDisabled} // Disable the button if no changes
                />
              </div>
            </div>
          )
        ) : active === "password" ? (
          loader ? (
            <div className="spinner-outer">
              <span className="spinner"></span>
            </div>
          ) : (
            <div className="password-content">
              <h3 className="Carmen-bold-font primarycolor">
                Change your password
              </h3>
              <div className="changepassword-div">
                <Profileinput
                  type="password"
                  label="Old Password"
                  placeholder="*********"
                  name="oldPassword"
                  value={oldPassword}
                  onchange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                />
                <Profileinput
                  type="password"
                  label="New Password"
                  placeholder="*********"
                  name="newPassword"
                  value={newPassword}
                  onchange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />
                <Profileinput
                  type="password"
                  label="Re Enter Password"
                  placeholder="*********"
                  name="confirmPassword"
                  value={confirmPassword}
                  onchange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
              </div>
              <div className="bottom">
                <Button
                  text="Discard Changes"
                  className="secondary-btn w-100 fs-16"
                  maxWidth="178px"
                  padding="12px 16px"
                  onClick={() => {
                    setOldPassword("");
                    setNewPassword("");
                    setConfirmPassword("");
                    toast.success("Changes Discarded Successfully!", {
                      toastId: "discardedsuccess",
                    });
                  }}
                  disabled={isDiscardDisabled} // Disable discard button if no changes
                />

                <Button
                  text="Save Changes"
                  className="primary-button w-100 fs-16 "
                  maxWidth="157px"
                  padding="12px 18px"
                  onClick={setPassword}
                  disabled={passwordLoader ? true : false}
                />
              </div>
            </div>
          )
        ) : active === "connect" ? (
          loader ? (
            <div className="spinner-outer">
              <span className="spinner"></span>
            </div>
          ) : (
            <div className="hubspot-divs">
              <div className="connect-content">
                <h3 className="Carmen-bold-font primarycolor">
                  Connect to Hubspot
                </h3>
                <h5 className="Carmen-regular-font greycolor">
                  Connect Hubspot account so that your phone book can able to
                  sync
                </h5>
                <Profileinput
                  type="text"
                  label="New Hubspot Api Key"
                  placeholder="Enter Key"
                  name="hubspot"
                  value={hubspot}
                  onchange={(e) => {
                    setHubspot(e.target.value);
                  }}
                />
                <Button
                  text="Connect"
                  className="primary-button w-100 fs-16 "
                  maxWidth="157px"
                  padding="12px 36px"
                  onClick={handleCheckHubspot}
                  disabled={hubspotLoader ? true : false}
                />
              </div>
              {hubspotData ? (
                <div className="connect-content">
                  <h3 className="Carmen-bold-font primarycolor">
                    Already connected to Hubspot
                  </h3>
                  <h5 className="Carmen-regular-font greycolor">
                    Your HubSpot account is successfully connected, and your
                    phone book is syncing.
                  </h5>
                  <Profileinput
                    type="text"
                    label="Connected Hubspot Api Key"
                    value={`${hubspotData.slice(
                      0,
                      4
                    )}**********${hubspotData.slice(-4)}`}
                    disabled={true}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          )
        ) : null}
      </section>
    </div>
  );
}

export default Settings;
