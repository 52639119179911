import React, { useState, useEffect, useRef, useContext } from "react";
import "./header.scss";
import Hamburger from "hamburger-react";
import Notification from "./notification";
import axios from "axios";
import { toast } from "react-toastify";
import { UserImageContext, UserNameContext } from "../../App";
import { useNavigate } from "react-router-dom";

function Header({ show, setShow }) {
  const navigate = useNavigate();
  const Apiurl = process.env.REACT_APP_API_KEY;
  const { userName, setUserName } = useContext(UserNameContext);
  const { imgData, setImgData } = useContext(UserImageContext);
  const logintoken = localStorage.getItem("authToken");
  const data = [
    {
      message: "Lorem ipsum dolor sit amet consectetur.",
      time: "04:38pm",
    },
    {
      message: "Lorem ipsum dolor sit amet consectetur.",
      time: "04:38pm",
    },
    {
      message: "Lorem ipsum dolor sit amet consectetur.",
      time: "04:38pm",
    },
    {
      message: "Lorem ipsum dolor sit amet consectetur.",
      time: "04:38pm",
    },
  ];
  const tokenverify = () => {
    const logintoken = localStorage.getItem("authToken");

    axios
      .get(`${Apiurl}/tokenVerification`, {
        headers: {
          Authorization: `Bearer ${logintoken}`,
        },
      })
      .then((response) => {
        console.log(response);
        setUserName(response?.data?.User?.firstName);
        setImgData(response?.data?.User?.Image);
      })
      .catch((error) => {
        // toast.error("User Expired", { toastId: "headerexpire" });
        localStorage.clear();
        navigate("/");
      });
  };

  useEffect(() => {
    tokenverify();
  }, []);

  return (
    <section className="header-sec">
      <h2 className="left Carmen-bold-font darkcolor">Dashboard</h2>
      <div className="right">
        {/* <Notification /> */}
        <div className="user">
          <div className="user-img">
            {imgData ? (
              <img src={imgData} alt="..." />
            ) : (
              <img src="/assets/images/user.jpg" alt="..." />
            )}
          </div>
          <div className="user-detail">
            <h5 className="darkcolor Carmen-regular-font">
              {userName ? userName : ""}
            </h5>
            {/* <p className="secondarycolor Carmen-regular-font">New User</p> */}
          </div>
        </div>
        <Hamburger toggled={show} toggle={setShow} />
      </div>
    </section>
  );
}

export default Header;
