import React, { useContext } from "react";
import { Select } from "antd";
import "./customselect.scss";
import { DataContext } from "../../App";
const CustomSelect = ({
  wrapperClasses,
  className,
  value,
  onChange,
  options,
  label,
  error,
  minHeight,
  maxHeight,
  minWidth,
  height,
  width,
  key,
  labelInValue = false,
  placeholder,
  showSearch = false,
  defaultValue,
  defaultActiveFirstOption = false,
}) => {
  // console.log("value", value);
  // const { data, setData } = useContext(DataContext);
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setData((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };
  const selectStyle = {
    minHeight: minHeight,
    maxHeight: maxHeight,
    minWidth: minWidth,
    height: height,
    width: width,
  };

  return (
    <div
      key={key}
      className={`select-wrapper ${wrapperClasses}  ${error ? "error" : ""} `}
    >
      {label && (
        <label className={value ? "active fw-semibold" : "fw-semibold"}>
          {" "}
          {label}
        </label>
      )}
      <Select
        defaultActiveFirstOption={defaultActiveFirstOption}
        style={selectStyle}
        onChange={onChange}
        options={options}
        defaultValue={defaultValue}
        value={value}
        className={"select-tameel"}
        popupClassName="select-tameel-dropdown"
        placeholder={placeholder}
      />
    </div>
  );
};

export default CustomSelect;
