import React from "react";
import "./campaignDetails.scss";
import Monitoring from "../../monitoring/monitoring";
function CampaignDetails() {
  return (
    <div className="dash-main">
      <Monitoring />
    </div>
  );
}

export default CampaignDetails;
