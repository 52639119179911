import React, { useRef, useState } from "react";
import Input from "../input/input";
import Button from "../button/button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
function Recovery() {
  const Apiurl = process.env.REACT_APP_API_KEY;
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    email: "",
  });
  const currentToastId = useRef("");
  const navigate = useNavigate();

  const recoveryApi = () => {
    setLoader(true);
    axios
      .post(`${Apiurl}/sendEmailVerify`, {
        email: data.email,
      })

      .then((res) => {
        if (currentToastId.current) {
          toast.dismiss(currentToastId.current);
        }
        toast.success("Code sent Successfully to your Email", {
          toastId: "loginsuccess",
        });
        setLoader(false);
        navigate(`/verify-code/${data.email}`);
      })
      .catch((err) => {
        currentToastId.current = toast.error(err?.response?.data?.message, {
          toastId: "loginfailure",
        });
        setLoader(false);
        console.log(err?.response?.data?.message);
        return;
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <section className="signin-sec">
      <div className="inner">
        <div className="inner-content">
          <div className="top-head">
            <h2 className="darkcolor Carmen-bold-font">Password Recovery</h2>
            <p className="Carmen-medium-fon greycolor">
              Enter your email to receive Verification Code{" "}
            </p>
          </div>
          <div className="bottom">
            <div className="inputs">
              <Input
                label="Email"
                type="email"
                placeholder="Enter your email here"
                name="email"
                onChange={handleChange}
                value={data.email}
              />

              <Button
                text="Continue"
                className="primary-button w-100 fs-20"
                maxWidth="356px"
                padding="13px"
                onClick={recoveryApi}
                disabled={loader ? true : false}
              />
            </div>
          </div>
        </div>
        <div className="back-layer"></div>
      </div>
    </section>
  );
}

export default Recovery;
