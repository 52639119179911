import React, { useState } from "react";
import "./sidebar.scss";
import Button from "../button/button";
import { NavLink, useNavigate } from "react-router-dom";
function Sidebar({ show, setShow }) {
  const navigate = useNavigate();

  const navigatetoHome = () => {
    navigate("/dashboard");
    setShow(false);
  };
  const navigatetoSignin = () => {
    localStorage.clear();
    // navigate("/");
    window.location.href = "/";
  };

  return (
    <section
      className={`sidebar-sec customscrollbar ${
        show ? "activesidebar" : "closesidebar"
      }`}
    >
      <div className="top">
        <img
          onClick={navigatetoHome}
          src="/assets/icons/logo.svg"
          alt="..."
          className="logo"
        />
      </div>
      <div className="center">
        <NavLink
          to="/dashboard"
          className="ist"
          onClick={() => {
            setShow(false);
          }}
        >
          <img src="/assets/icons/dashboard.svg" alt="..." />
          <h5 className="Carmen-medium-font greycolor">Dashboard</h5>
        </NavLink>
        <NavLink
          to="/agents"
          className="ist"
          onClick={() => {
            setShow(false);
          }}
        >
          <img src="/assets/icons/agents.svg" alt="..." />
          <h5 className="Carmen-medium-font greycolor">Agents</h5>
        </NavLink>
        <NavLink
          to="/knowledge-base"
          className="ist"
          onClick={() => {
            setShow(false);
          }}
        >
          <img src="/assets/icons/knowledgebase.svg" alt="..." />
          <h5 className="Carmen-medium-font greycolor">Knowledge Base</h5>
        </NavLink>
        {/* <NavLink
          to="/monitoring"
          className="ist"
          onClick={() => {
            setShow(false);
          }}
        >
          <img src="/assets/icons/monitoring.svg" alt="..." />
          <h5 className="Carmen-medium-font greycolor">Monitoring</h5>
        </NavLink> */}
        <NavLink
          to="/contacts-list"
          className="ist"
          onClick={() => {
            setShow(false);
          }}
        >
          <img src="/assets/icons/contacts.svg" alt="..." />
          <h5 className="Carmen-medium-font greycolor">Contacts List</h5>
        </NavLink>
        <NavLink
          to="/campaigns"
          className="ist"
          onClick={() => {
            setShow(false);
          }}
        >
          <img src="/assets/icons/campaigns.svg" alt="..." />
          <h5 className="Carmen-medium-font greycolor">Campaigns</h5>
        </NavLink>
        <NavLink
          to="/history"
          className="ist"
          onClick={() => {
            setShow(false);
          }}
        >
          <img src="/assets/icons/history.svg" alt="..." />
          <h5 className="Carmen-medium-font greycolor">History</h5>
        </NavLink>
        <NavLink
          to="/settings"
          className="ist"
          onClick={() => {
            setShow(false);
          }}
        >
          <img src="/assets/icons/settings.svg" alt="..." />
          <h5 className="Carmen-medium-font greycolor">Settings</h5>
        </NavLink>
      </div>
      <div className="bottom">
        <Button
          text="Connect to HubSpot"
          className="secondary-btn w-100 fs-16"
          maxWidth="232px"
          padding="13px 26px"
          onClick={() => {
            setShow(false);
            navigate("/settings", { state: "connect" });
          }}
        />
        <Button
          text="Logout"
          className="primary-button w-100 fs-18"
          maxWidth="232px"
          padding="13px 26px"
          onClick={navigatetoSignin}
        />
      </div>
    </section>
  );
}

export default Sidebar;
