import React, { useEffect, useState } from "react";
import "./history.scss";
import { DatePicker } from "@gsebdev/react-simple-datepicker";
import { Dropdown } from "primereact/dropdown";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Button from "../button/button";

function History() {
  const [historyData, setHistoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortedDate, setSortedDate] = useState(null);
  const [status, setStatus] = useState(null);
  const [transcription, setTranscription] = useState([]);
  const [transcriptionLoading, setTranscriptionLoading] = useState(false);
  const [detailData, setDetailData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [statusOrder, setStatusOrder] = useState([]);
  const dateOrder = ["Ascending", "Descending"];
  const Apiurl = process.env.REACT_APP_API_KEY;
  const logintoken = localStorage.getItem("authToken");
  const [loader, setLoader] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const accountSID = "ACb43c8c6cc6de5931c1b1de37449cae97";
  const authToken = "3e08ce6776ab8e4f6d29c67b4d729f64";
  const [callLoader, setCallLoader] = useState(false);
  const [visibleCount, setVisibleCount] = useState(20); // For load more functionality
  const handleModalClose = () => setModalShow(false);
  const call_backend_url = process.env.REACT_APP_AWS_BASE_URL;
  const handleModalShow = (item) => {
    console.log(item);
    setDetailData(item);
    setModalShow(true);
    getTranscription(item.sid);
    callFunc(item.sid);
  };

  const getHistoryApi = () => {
    setLoader(true);
    axios
      .get(`${Apiurl}/getCallLogs`, {
        headers: {
          Authorization: `Bearer ${logintoken}`,
        },
      })
      .then((response) => {
        console.log(response.data);

        setHistoryData(response.data.calls);
        setFilteredData(response.data.calls); // set initial filtered data
        const statuses = [
          ...new Set(response.data.calls.map((item) => item.status)),
        ];
        setStatusOrder(statuses);
        setLoader(false);
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };

  const callFunc = async (id) => {
    setCallLoader(true);
    setAudioUrl("");
    const fileFormat = "mp3";
    const listRecordingsUrl = `https://api.twilio.com/2010-04-01/Accounts/${accountSID}/Recordings.json?CallSid=${id}`;

    const response = await axios({
      method: "get",
      url: listRecordingsUrl,
      auth: {
        username: accountSID,
        password: authToken,
      },
    });
    const recordings = response.data.recordings;
    if (recordings.length === 0) {
      console.log("No recordings found for this Call SID.");
      setCallLoader(false);
      return;
    }
    const recordingSid = recordings[0].sid;
    const recordingUrl = `https://api.twilio.com/2010-04-01/Accounts/${accountSID}/Recordings/${recordingSid}.${fileFormat}`;
    try {
      const responseAudio = await axios({
        method: "get",
        url: recordingUrl,
        auth: {
          username: accountSID,
          password: authToken,
        },
        responseType: "arraybuffer",
      });

      const audioBlob = new Blob([responseAudio.data], {
        type: `audio/${fileFormat}`,
      });
      const audioObjectUrl = URL.createObjectURL(audioBlob);

      const audio = new Audio(audioObjectUrl);

      audio.addEventListener("loadedmetadata", () => {
        const duration = audio.duration;
        if (duration > 0 && duration <= 2) {
          console.log("Valid audio, duration:", duration, "seconds");
        } else if (duration === 0) {
          console.log("Audio is empty or invalid");
        } else {
          console.log("Audio duration exceeds 2 seconds:", duration, "seconds");
          setAudioUrl(audioObjectUrl);
        }
      });

      setCallLoader(false);
    } catch (error) {
      setCallLoader(false);
      console.error(`Failed to download recording: ${error.message}`);
    }
  };

  // Call transcription

  const getTranscription = (id) => {
    setTranscriptionLoading(true);
    axios
      .post(
        `${call_backend_url}/getCallSidTranscription`,
        {
          twilio_sid: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${logintoken}`,
          },
        }
      )
      .then((response) => {
        setTranscription(response.data);
        setTranscriptionLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the data:", error);
        setTranscriptionLoading(false);
      });
  };

  useEffect(() => {
    getHistoryApi();
  }, []);

  // Filtering logic
  useEffect(() => {
    let data = historyData;

    // Filter by "From" and "To" numbers
    if (searchText) {
      data = data.filter(
        (item) => item.from.includes(searchText) || item.to.includes(searchText)
      );
    }

    // Filter by date range
    if (fromDate) {
      data = data.filter(
        (item) => new Date(item.startTime) >= new Date(fromDate)
      );
    }
    if (toDate) {
      data = data.filter(
        (item) => new Date(item.startTime) <= new Date(toDate)
      );
    }

    // Sort by date
    if (sortedDate === "Ascending") {
      data = data.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
    } else if (sortedDate === "Descending") {
      data = data.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));
    }

    // Filter by status
    if (status) {
      data = data.filter((item) => item.status === status);
    }

    setFilteredData(data);
  }, [searchText, fromDate, toDate, sortedDate, status, historyData]);

  // Load more functionality
  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 20);
  };
  console.log(toDate);
  return (
    <div className="dash-main">
      <div className="history-sec">
        <div className="d-flex justify-content-between align-itmes-center">
          <h3 className="heading primarycolor Carmen-bold-font">History</h3>
        </div>
        <div className="dates">
          <div className="dates-inner">
            <h5 className="darkcolor Carmen-bold-font">Search</h5>
            <div className="search-div">
              <img
                src="/assets/icons/search.svg"
                alt="..."
                className="pointer"
              />
              <input
                type="number"
                placeholder="Search here"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
          <div className="dates-inner">
            <h5 className="darkcolor Carmen-bold-font">From Date</h5>
            <div className="search-div">
              <DatePicker
                id="datepicker-id"
                name="date-demo"
                value={fromDate}
                placeholder="mm/dd/yy"
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>
          </div>
          <div className="dates-inner">
            <h5 className="darkcolor Carmen-bold-font">To Date</h5>
            <div className="search-div">
              <DatePicker
                id="datepicker-id"
                name="date-demo"
                value={toDate}
                placeholder="mm/dd/yy"
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
          </div>
          <div className="dates-inner">
            <h5 className="darkcolor Carmen-bold-font ">Sort by Date</h5>
            <div className="search-div">
              <Dropdown
                value={sortedDate}
                onChange={(e) => setSortedDate(e.value)}
                options={dateOrder}
                optionLabel="order"
                placeholder="Order"
              />
            </div>
          </div>
          <div className="dates-inner">
            <h5 className="darkcolor Carmen-bold-font">Sort by Status</h5>
            <div className="search-div customscrollbar">
              <Dropdown
                value={status}
                onChange={(e) => setStatus(e.value)}
                options={statusOrder}
                optionLabel="order"
                placeholder="Select Status"
              />
            </div>
          </div>
        </div>
        <div className="contactTable-outer">
          <div className="contact-table">
            <div className="table-head">
              <div className="third">
                <p className="darkcolor Carmen-medium-font">From</p>
              </div>
              <div className="third">
                <p className="darkcolor Carmen-medium-font">To</p>
              </div>
              <div className="third">
                <p className="darkcolor Carmen-medium-font">Start Time</p>
              </div>
              <div className="third">
                <p className="darkcolor Carmen-medium-font">End Time</p>
              </div>
              <div className="third">
                <p className="darkcolor Carmen-medium-font">Duration(sec)</p>
              </div>
              <div className="third">
                <p className="darkcolor Carmen-medium-font">Status</p>
              </div>
              <div className="third">
                <p className="darkcolor Carmen-medium-font">Action</p>
              </div>
            </div>

            {loader ? (
              <div className="spinner-outer">
                <span className="spinner"></span>
              </div>
            ) : (
              <div className="table-body customscrollbar">
                {filteredData && filteredData.length > 0 ? (
                  filteredData.slice(0, visibleCount).map((item, index) => (
                    <div className="contact-detail" id={item.sid} key={index}>
                      <div className="third">
                        <p className="small-para greycolor Carmen-regular-font">
                          {item.from}
                        </p>
                      </div>
                      <div className="third">
                        <p className="small-para greycolor Carmen-regular-font">
                          {item.to}
                        </p>
                      </div>
                      <div className="third">
                        <p className="small-para greycolor Carmen-regular-font">
                          {
                            new Date(item.startTime)
                              .toISOString()
                              .split("T")[1]
                              .split(".")[0]
                          }
                        </p>
                      </div>
                      <div className="third">
                        <p className="small-para greycolor Carmen-regular-font">
                          {
                            new Date(item.endTime)
                              .toISOString()
                              .split("T")[1]
                              .split(".")[0]
                          }
                        </p>
                      </div>
                      <div className="third">
                        <p className="small-para greycolor Carmen-regular-font">
                          {item.duration}
                        </p>
                      </div>
                      <div className="third">
                        <p
                          className={`small-para Carmen-medium-font ${
                            item.status === "Pending"
                              ? "redcolor"
                              : item.status === "Completed"
                              ? "bluecolor"
                              : "primarycolor"
                          }`}
                        >
                          {item.status}
                        </p>
                      </div>
                      <div className="third">
                        <p
                          className="pointer small-para primarycolor Carmen-medium-font"
                          onClick={() => {
                            handleModalShow(item);
                          }}
                        >
                          See Details
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="pt-2 px-3 mx-auto">No data</p>
                )}
              </div>
            )}
          </div>

          {/* Load More Button */}
          {loader
            ? " "
            : visibleCount < filteredData.length && (
                <div className="load-more">
                  <Button
                    text="Load More"
                    className="primary-button w-100 fs-16 mx-auto my-2"
                    maxWidth="140px"
                    padding="10px 20px"
                    onClick={loadMore}
                  />
                </div>
              )}
        </div>
      </div>
      <Modal
        show={modalShow}
        onHide={handleModalClose}
        centered
        size="lg"
        className="history-modal"
      >
        <Modal.Body>
          <div className="head d-flex align-items-center justify-content-between">
            <h3 className="Carmen-semibold-font primarycolor">Details</h3>
            <img
              src="/assets/icons/modalclose.svg"
              alt="..."
              className="pointer close-btn"
              onClick={handleModalClose}
            />
          </div>
          <div className="detail-divs">
            <div>
              <p className="darkcolor Carmen-medium-font">
                To: {detailData.to}
              </p>
            </div>
            <div>
              <p className="darkcolor Carmen-medium-font">
                From: {detailData.from}
              </p>
            </div>
            <div>
              <p className="darkcolor Carmen-medium-font">
                Start Time:
                {detailData.startTime &&
                  new Date(detailData.startTime)
                    .toISOString()
                    .split("T")[1]
                    .split(".")[0]}
              </p>
            </div>
            <div>
              <p className="darkcolor Carmen-medium-font">
                End Time:{" "}
                {detailData.endTime &&
                  new Date(detailData.endTime)
                    .toISOString()
                    .split("T")[1]
                    .split(".")[0]}
              </p>
            </div>
            <div>
              <p className="darkcolor Carmen-medium-font">
                Status: {detailData.status}
              </p>
            </div>
            <div>
              <p className="darkcolor Carmen-medium-font">
                Duration: {detailData.duration}s
              </p>
            </div>
          </div>
          <h3 className="Carmen-semibold-font primarycolor">
            Call Transcription
          </h3>

          {transcriptionLoading ? (
            <div
              className="d-flex align-items-center m-auto "
              style={{ height: "100px" }}
            >
              <span className="spinner"></span>
            </div>
          ) : (
            <div className="call-transcription customscrollbar">
              {transcription && transcription.length > 0 ? (
                transcription.map((item) => (
                  <div className={`${item.speaker == "Ai" ? `left` : "right"}`}>
                    <p className="small-para">{item.message}</p>
                  </div>
                ))
              ) : (
                <p>Transcription not found</p>
              )}
            </div>
          )}

          <h3 className="Carmen-semibold-font primarycolor">Call Recording</h3>
          <div className="w-100">
            {callLoader ? (
              <div
                className="d-flex align-items-center justify-content-center m-auto "
                style={{ height: "100px" }}
              >
                <span className="spinner"></span>
              </div>
            ) : audioUrl ? (
              <audio controls className="w-100">
                <source src={audioUrl} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            ) : (
              <p>No Audio</p>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default History;
