import React, { useEffect, useRef, useState } from "react";
import "./createcompaign.scss";
import Button from "../../button/button";
import Inputdash from "../../Inputdash/inputdash";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

function Createcompaign() {
  const navigate = useNavigate();
  const [selectAgent, setSelectAgent] = useState(false);
  const [selectContact, setSelectContact] = useState(false);
  const [loader, setLoader] = useState(false);
  const [agentLoader, setAgentLoader] = useState(false);
  const [listLoader, setListLoader] = useState(false);
  const [agentData, setAgentData] = useState([]);
  const [listData, setListData] = useState([]);
  const [agentName, setAgentName] = useState("");
  const [agentId, setAgentId] = useState("");
  const [listName, setListName] = useState("");
  const [listId, setListId] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [campaignDescription, setCampaignDescription] = useState("");
  const [show, setShow] = useState(false);

  // New state for search queries
  const [searchAgent, setSearchAgent] = useState("");
  const [searchContact, setSearchContact] = useState("");

  const handleClose = () => {
    navigate("/campaigns");
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const navigateToCampaigns = () => {
    navigate("/campaigns");
  };

  const Apiurl = process.env.REACT_APP_API_KEY;
  const logintoken = localStorage.getItem("authToken");
  const agentRef = useRef(null);
  const contactRef = useRef(null);

  const handleSelectAgent = () => {
    setSelectAgent(!selectAgent);
  };

  const handleSelectContact = () => {
    setSelectContact(!selectContact);
  };

  const handleOutside = (event) => {
    if (agentRef.current && !agentRef.current.contains(event.target)) {
      setSelectAgent(false);
      setSearchAgent("");
    }
    if (contactRef.current && !contactRef.current.contains(event.target)) {
      setSelectContact(false);
      setSearchContact("");
    }
  };

  // Create Campaign Api
  const CreateCampaign = () => {
    setLoader(true);
    const options = {
      method: "POST",
      url: `${Apiurl}/Campagin`,
      headers: {
        "User-Agent": "insomnia/10.0.0",
        Authorization: `Bearer ${logintoken}`,
      },
      data: {
        campaignName: campaignName,
        agent: agentId,
        contactList: listId,
        campaignDescription: campaignDescription,
      },
    };

    if (
      campaignName === "" ||
      agentId === "" ||
      listId === "" ||
      campaignDescription === ""
    ) {
      toast.error("Please select all fields", { toastId: "create err" });
      setLoader(false);
    } else {
      axios
        .request(options)
        .then(function (response) {
          handleShow();
          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutside);
    return () => {
      document.removeEventListener("mousedown", handleOutside);
    };
  }, []);

  // Get agents api
  const agentsApi = () => {
    setAgentLoader(true);
    axios
      .get(`${Apiurl}/getAllAgent`, {
        headers: {
          Authorization: `Bearer ${logintoken}`,
        },
      })
      .then((res) => {
        setAgentData(res.data.response || []);
        setAgentLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setAgentLoader(false);
      });
  };

  // Get Lists Api
  const getListApi = () => {
    setListLoader(true);
    const options = {
      method: "GET",
      url: `${Apiurl}/getallcontacts`,
      headers: {
        "User-Agent": "insomnia/10.0.0",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        setListData(response.data);
        setListLoader(false);
      })
      .catch(function (error) {
        console.error(error);
        setListLoader(false);
      });
  };

  useEffect(() => {
    agentsApi();
    getListApi();
  }, []);

  const filteredAgents = agentData
    .filter((agent) => agent.AgentTitle === "Outbound_Call")
    .filter((agent) =>
      agent.AgentName.toLowerCase().includes(searchAgent.toLowerCase())
    );

  const filteredContacts = listData.filter((contact) =>
    contact.fileName.toLowerCase().includes(searchContact.toLowerCase())
  );

  return (
    <div className="dash-main">
      <div className="createCompaign-sec">
        <h3 className="heading primarycolor Carmen-bold-font">
          Create New Campaign
        </h3>
        <div className="creation-content">
          <div className="ist">
            <Inputdash
              type="text"
              label="Campaign Name"
              placeholder="Campaign Name"
              value={campaignName}
              name="campaignName"
              onchange={(e) => {
                setCampaignName(e.target.value);
              }}
            />
            <div className="ist-inner" ref={agentRef}>
              <h5 className="darkcolor Carmen-semibold-font">Agent</h5>
              <div className="select-agent ">
                <div
                  className="select-inner pointer"
                  onClick={handleSelectAgent}
                >
                  {agentName ? (
                    <h4 className="darkcolor Carmen-regular-font">
                      {agentName}
                    </h4>
                  ) : (
                    <h4
                      className="greycolor Carmen-regular-font"
                      style={{ opacity: "0.2" }}
                    >
                      All Agents
                    </h4>
                  )}
                  {!selectAgent ? (
                    <Icon icon="mingcute:down-line" />
                  ) : (
                    <Icon icon="mingcute:up-line" />
                  )}
                </div>
                {selectAgent && (
                  <div className="select-options">
                    <div className="search-bar">
                      <img
                        src="/assets/icons/search.svg"
                        alt="..."
                        className="pointer"
                      />
                      <input
                        type="text"
                        placeholder="Search here"
                        value={searchAgent}
                        onChange={(e) => setSearchAgent(e.target.value)} // Update search agent state
                      />
                    </div>
                    <p className="greycolor Carmen-regular-font">Lists</p>
                    <div className="agents customscrollbar">
                      {filteredAgents.length === 0 ? (
                        <p>No data</p>
                      ) : (
                        filteredAgents.map((item, index) => (
                          <h5
                            key={index}
                            className="greycolor Carmen-regular-font"
                            onClick={() => {
                              setSelectAgent(false);
                              setAgentName(item.AgentName);
                              setAgentId(item._id);
                            }}
                          >
                            {item.AgentName}
                          </h5>
                        ))
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="ist-inner" ref={contactRef}>
              <h5 className="darkcolor Carmen-semibold-font">
                Select Contact List
              </h5>
              <div className="select-agent ">
                <div
                  className="select-inner pointer"
                  onClick={handleSelectContact}
                >
                  {listName ? (
                    <h4 className="darkcolor Carmen-regular-font">
                      {listName.length > 16
                        ? listName.substring(0, 16) + "..."
                        : listName}
                    </h4>
                  ) : (
                    <h4
                      className="greycolor Carmen-regular-font"
                      style={{ opacity: "0.2" }}
                    >
                      All Contacts
                    </h4>
                  )}

                  {!selectContact ? (
                    <Icon icon="mingcute:down-line" />
                  ) : (
                    <Icon icon="mingcute:up-line" />
                  )}
                </div>
                {selectContact && (
                  <div className="select-options">
                    <div className="search-bar">
                      <img
                        src="/assets/icons/search.svg"
                        alt="..."
                        className="pointer"
                      />
                      <input
                        type="text"
                        placeholder="Search here"
                        value={searchContact}
                        onChange={(e) => setSearchContact(e.target.value)} // Update search contact state
                      />
                    </div>

                    <p className="greycolor Carmen-regular-font">Lists</p>

                    <div className="agents customscrollbar">
                      {filteredContacts.length === 0 ? (
                        <p>No data</p>
                      ) : (
                        filteredContacts.map((item, index) => (
                          <h5
                            key={index}
                            className="greycolor Carmen-regular-font"
                            onClick={() => {
                              setSelectContact(false);
                              setListName(item.fileName);
                              setListId(item._id);
                            }}
                          >
                            {item.fileName}
                          </h5>
                        ))
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Inputdash
            label="Campaign Description"
            placeholder="Unlock your business's potential with our tailored loan solutions. Fast, efficient, and designed to meet your needs. Contact us today to learn more!"
            value={campaignDescription}
            name="campaignDescription"
            onchange={(e) => {
              setCampaignDescription(e.target.value);
            }}
          />

          <div className="bottom">
            <Button
              text="Discard "
              className="secondary-btn w-100 fs-20"
              maxWidth="157px"
              padding="12px 36px"
              onClick={navigateToCampaigns}
            />
            <Button
              text="Create"
              className="primary-button w-100 fs-20 "
              maxWidth="157px"
              padding="12px 36px"
              onClick={CreateCampaign}
              disabled={loader}
            />
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered className="advance-save">
        <Modal.Body>
          <img src="/assets/icons/congrats.svg" alt="..." />
          <div>
            <h4 className="Carmen-semibold-font primarycolor">
              Congratulations
            </h4>
            <h4 className="Carmen-bold-font secondarycolor">
              Campaign Created Successfully
            </h4>
          </div>
          <Button
            text="Finish"
            className="primary-button w-100 fs-20 mx-auto"
            maxWidth="207px"
            padding="12px 36px"
            onClick={handleClose}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Createcompaign;
