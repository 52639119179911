import axios from "axios";
import React, { Children, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ProtectedRoutes = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const Apiurl = process.env.REACT_APP_API_KEY;

  const tokenverify = () => {
    const logintoken = localStorage.getItem("authToken");
    console.log(logintoken);

    axios
      .get(`${Apiurl}/tokenVerification`, {
        headers: {
          Authorization: `Bearer ${logintoken}`,
        },
      })
      .then((response) => {
        console.log(response);

        // navigate("/dashboard");
      })
      .catch((error) => {
        toast.error("User Expired", { toastId: "expiretoast" });

        localStorage.clear();
        navigate("/");
        console.log(error);
      });
  };
  useEffect(() => {
    console.log("Protected UseEffect");
    tokenverify();
    if (!localStorage.getItem("authToken")) {
      navigate("/");
    }
  }, []);
  return <div>{children}</div>;
};

export default ProtectedRoutes;
