import React, { useContext, useState } from "react";
import "./script.scss";
import Button from "../button/button";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "../../App";
import { toast } from "react-toastify";
function Script() {
  const location = useLocation();
  const { data, setData } = useContext(DataContext);
  const navigate = useNavigate();
  const [disableText, setDisableText] = useState(true);
  const [disableConfinement, setDisableConfinement] = useState(true);
  const navigateToagent = () => {
    navigate("/create-agent");
  };
  const navigateadvance = () => {
    const combinedScript = data.Scriptshow + " " + data.confinement;
    setData((prevData) => ({
      ...prevData,
      Script: combinedScript,
    }));

    if (!combinedScript) {
      toast.error("Script can't be empty", {
        toastId: "scripttoast",
      });
      return;
    }

    navigate("/advanced");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <div className="dash-main">
      <div className="script-outer">
        <div className="topTabs-outer">
          <div className="top-tabs">
            <div className="top-tab">
              <div className="number-div active-div">
                <p className="small-para Carmen-semibold-font">1</p>
              </div>
              <p className="small-para Carmen-semibold-font active-agent ">
                Create Agent
              </p>
            </div>
            <div className="top-line active-div"></div>
            <div className="top-tab ">
              <div className="number-div active-div">
                <p className="small-para Carmen-semibold-font ">2 </p>
              </div>
              <p className="small-para Carmen-semibold-font active-agent">
                Update Script
              </p>
            </div>
            <div className="top-line"></div>
            <div className="top-tab">
              <div className="number-div">
                <p className="small-para Carmen-semibold-font ">3</p>
              </div>
              <p className="small-para Carmen-semibold-font tab-agent">
                Advanced Setting
              </p>
            </div>
          </div>
        </div>
        <section className="script-sec">
          <div className="head">
            <h3 className="heading primarycolor Carmen-semibold-font">
              Script
            </h3>
            {disableText ? (
              <Button
                text="Edit"
                className="primary-button w-100 fs-16 "
                maxWidth="92px"
                padding="10px 20px"
                onClick={() => {
                  setDisableText(false);
                }}
              />
            ) : (
              <Button
                text="Save"
                className="primary-button w-100 fs-16 "
                maxWidth="92px"
                padding="10px 20px"
                onClick={() => {
                  setDisableText(true);
                }}
              />
            )}
          </div>
          <textarea
            className="Carmen-regular-font greycolor customscrollbar"
            disabled={disableText}
            onChange={handleChange}
            value={data.Scriptshow}
            name="Scriptshow"
          >
            {data.Scriptshow}
          </textarea>
        </section>
        <section className="script-sec">
          <div className="head">
            <h3 className="heading primarycolor Carmen-semibold-font">
              Confinement and Instructions
            </h3>
            {disableConfinement ? (
              <Button
                text="Edit"
                className="primary-button w-100 fs-16 "
                maxWidth="92px"
                padding="10px 20px"
                onClick={() => {
                  setDisableConfinement(false);
                }}
              />
            ) : (
              <Button
                text="Save"
                className="primary-button w-100 fs-16 "
                maxWidth="92px"
                padding="10px 20px"
                onClick={() => {
                  setDisableConfinement(true);
                }}
              />
            )}
          </div>
          <textarea
            className="Carmen-regular-font greycolor customscrollbar"
            disabled={disableConfinement}
            onChange={handleChange}
            value={data.confinement}
            name="confinement"
          >
            {data.confinement}
          </textarea>
          <div className="bottom">
            <Button
              text="Back"
              className="secondary-btn w-100 fs-20"
              maxWidth="157px"
              padding="12px 36px"
              onClick={navigateToagent}
            />
            <Button
              text="Next"
              className="primary-button w-100 fs-20 "
              maxWidth="157px"
              padding="12px 36px"
              onClick={navigateadvance}
            />
          </div>
          <Outlet />
        </section>
        <Outlet />
      </div>
    </div>
  );
}

export default Script;
