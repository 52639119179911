import React, { useState } from "react";
import "./input.scss";
import { Icon } from "@iconify/react/dist/iconify.js";
function Input({ placeholder, label, type, value, name, onChange }) {
  const [show, setShow] = useState(false);
  const showpassword = () => {
    setShow(!show);
  };
  return (
    <section className="input-sec">
      <label className="small-para Carmen-regular-font">{label}</label>
      <div className="input-div">
        <input
          type={show ? "text" : type}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
        />
        {type === "password" && (
          <>
            {!show ? (
              <Icon
                icon="mdi:eye"
                style={{ color: "#A9A9A9" }}
                onClick={showpassword}
              />
            ) : (
              <Icon
                icon="mdi:eye-off"
                style={{ color: "#A9A9A9" }}
                onClick={showpassword}
              />
            )}
          </>
        )}
      </div>
    </section>
  );
}

export default Input;
