import axios from "axios";
import { toast } from "react-toastify";

export const Getvoices = (customapi) => {
  return new Promise((resolve, reject) => {
    const Elevenkey = process.env.REACT_APP_ELEVEN_LABS_API_KEY;
    console.log(Elevenkey);
    const apiKey = customapi || Elevenkey;
    console.log(apiKey);
    axios
      .get("https://api.elevenlabs.io/v1/voices", {
        headers: {
          "xi-api-key": apiKey,
        },
      })
      .then((res) => {
        console.log(res.data);
        resolve(res.data.voices);
      })
      .catch((err) => {
        toast.error("Invalid ID", { toastId: "voiceerror" });
        reject([]);
      });

    // resolve(response.data);
    // setVoices(response.data);
  });
};
